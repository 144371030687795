import React, { useState } from "react";
// import "../css/main.css"
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { loadStripe } from "@stripe/stripe-js";
import { CartProvider } from "use-shopping-cart";
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);
const Layout = ({ processed, children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <>
      <CartProvider
        mode="client-only"
        stripe={stripePromise}
        successUrl={`http://www.depthlearning.co.uk/basket?card=success&session_id={CHECKOUT_SESSION_ID}`}
        cancelUrl="https://www.depthlearning.co.uk/"
        currency="GBP"
        allowedCountries={["GB"]}
        billingAddressCollection={true}
      >
        <Navbar toggleSidebar={toggleSidebar} processed={processed} />
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        {children}
      </CartProvider>
      <Footer />
    </>
  );
};

export default Layout;
