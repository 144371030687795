import React from "react"
import { Link } from "gatsby"
import { FaCartPlus } from "react-icons/fa"
const data = [
  {
    id: 1,
    text: "Home",
    url: "/",
  },
  {
    id: 2,
    text: "Resources",
    url: "/resources",
  },
  {
    id: 5,
    text: "Basket",
    url: "/basket",
  },
  {
    id: 3,
    text: "About",
    url: "/about",
  },
  {
    id: 4,
    text: "Contact",
    url: "/contact",
  },
]

const tempLinks = data.map(link => (
  <li key={link.id}>
    <Link to={link.url}>{link.text}</Link>
  </li>
))

export default ({ styleClass, cartCount }) => (
  <ul className={`page-links ${styleClass ? styleClass : ""}`}>
    {cartCount > 0 ? (
      <Link className="basket" to="/basket">
        <div style={{ fontWeight: "bold" }}>
          <FaCartPlus></FaCartPlus>
          {` ${cartCount}`}
        </div>
      </Link>
    ) : null}
    {tempLinks}
  </ul>
)
