import React from "react";
import logo from "../assets/logo.svg";
import PageLinks from "../constants/links";
import { FaAlignRight, FaCartPlus } from "react-icons/fa";
import { useShoppingCart } from "use-shopping-cart";
import useDeviceDetect from "../utils/detectDevice";
import { Link } from "gatsby";
const Navbar = ({ processed, toggleSidebar }) => {
  const { cartCount, clearCart } = useShoppingCart();
  const { isMobile } = useDeviceDetect();
  if (processed) {
    clearCart();
  }
  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
        <Link to="/">
          <img src={logo} alt="logo" className="nav-logo" />
          </Link>
          {isMobile ? (
            <Link className="mob-basket" to="/basket">
              <FaCartPlus></FaCartPlus>
              {` ${cartCount}`}
            </Link>
          ) : null}
          <button type="button" className="toggle-btn" onClick={() => toggleSidebar()}>
            <FaAlignRight></FaAlignRight>
          </button>
        </div>

        <PageLinks styleClass="nav-links" cartCount={cartCount}></PageLinks>
      </div>
    </nav>
  );
};

export default Navbar;
